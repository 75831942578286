import AlephBet from "alephbet"
import {storage_adapter, lamed_adapter} from "./adapters"
import {not_logged_in, on_video_show_page} from "./helpers"
import {registered_successfully} from "./goals"

export default () => {
  const stronger_cta_on_video_pages = new AlephBet.Experiment({
    name: "202502 Stronger CTA on video pages",
    tracking_adapter: lamed_adapter,
    storage_adapter: storage_adapter,
    trigger: () => not_logged_in() && on_video_show_page(),
    variants: {
      "1-Original": {
        activate: () => {
          window.gon.show_video_preview_modal = false
        }
      },
      "2-stronger-cta": {
        activate: (experiment) => {
          if (!experiment.options.trigger()) return
          window.gon.show_video_preview_modal = true
        }
      }
    }
  })

  stronger_cta_on_video_pages.add_goals([
    registered_successfully
  ])
}
